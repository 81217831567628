/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-24 13:43:00
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-05-06 10:29:04
 */
import request from '@/request/request'
// 紧急求援页
// 列表
export function all(data) {
    return request({
        url:'/Maintenance/Rescue/List',
        method:'post',
        data
    })
}
export function housesList(data) {
    return request({
        url: '/Maintenance/HousesSelectList',
        method: 'post',
        data
    })
}
// 详细数据
export function detail(data){
    return request({
        url:'/Maintenance/Rescue/Info',
        method:'post',
        data
    })
}
// 接单
export function receiveOrder(data){
    return request({
        url:'/Maintenance/Rescue/ReceiveOrder',
        method:'post',
        data
    })
}
// 处理工单
export function arriveOrder(data){
    return request({
        url:'/Maintenance/Rescue/ArriveOrder',
        method:'post',
        data
    })
}
// 下拉筛选按钮  就是转单的时候把人员搞出来
export function staffSelectList(data){
    return request({
        url:'/Maintenance/CompanyStaffSelectList',
        method:'post',
        data
    })
}
// 转单
export function transferOrder(data){
    return request({
        url:'/Maintenance/Rescue/TransferOrder',
        method:'post',
        data
    })
}
// 完结
export function successOrder(data){
    return request({
        url:'/Maintenance/Rescue/SuccessOrder',
        method:'post',
        data
    })
}
